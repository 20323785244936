import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import Popup from 'ui/component/popup/popup';
import { ScrollToElementBtn } from 'ui/component/native-reviews/hooks/scroll-to-element-btn';
import { QuestionForm } from 'ui/component/question-answer/question-form';
import { CREATE_QUESTION_MUTATION } from 'graphql/question-answer/mutations/createQuestion';
import AddIcon from 'assets/images/add-icon.svg';
import {
    CreateQuestionInput,
    CreateQuestionResponse,
    IQuestionDataRecaptcha,
} from 'ui/util/type-helper';
import style from './styles.css';

interface IQuestionPopupProps {
    account: AccountState;
    pageType: string;
    identifier: string;
    storeId: number;
    questionCount?: number;
    allowGuestUserToQuestion?: boolean;
    children: React.ReactNode;
}

interface ErrorItem {
    message: string;
}

const QuestionPopup: React.FC<IQuestionPopupProps> = ({
    account,
    pageType,
    identifier,
    storeId,
    questionCount,
    allowGuestUserToQuestion,
    children,
}) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [
        createQuestion,
        { data, loading, error },
    ] = useMutation<CreateQuestionResponse, CreateQuestionInput>(CREATE_QUESTION_MUTATION);

    const t = usePhraseTranslater();

    const qaCountText = t('Question & Answer');

    const handleOpenPopup = () => {
        setIsPopupVisible(true);
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    const submitQuestion = async (questionData: IQuestionDataRecaptcha):
    Promise<{ success: boolean; data: {questionId: number|string}|{errors?: ErrorItem[] } | null }> => {
        const requestBody: CreateQuestionInput = {
            author: questionData.author,
            email: questionData.email,
            question: questionData.question,
            page_type: pageType,
            display_at: storeId,
            identifier,
            customer_id: account.isLoggedIn ? account?.info?.id : undefined,
        };

        try {
            const context = questionData?.recaptchaToken ? {
                headers: {
                    'X-ReCaptcha': questionData?.recaptchaToken,
                },
            } : {};

            const { errors, data } = await createQuestion({
                context,
                variables: requestBody,
            });

            if (data?.createQuestion?.question_id) {
                return {
                    success: true,
                    data: {
                        questionId: data?.createQuestion?.question_id,
                    },
                };
            }
            if (errors) {
                const errorItems: ErrorItem[] = errors.map(err => ({
                    message: err.message,
                }));

                return {
                    success: false,
                    data: {
                        errors: errorItems,
                    },
                };
            }
            return {
                success: false,
                data: null,
            };
        } catch (e) {
            return {
                success: false,
                data: null,
            };
        }
    };

    return (
        <div>
            <div className={style.linkLabelCnt}>
                <div className={style.labelIcnCnt}>
                    <div className={style.icnCntCss}>
                        <div className={style.questionCountCircleCnt}>
                            <span>{questionCount}</span>
                        </div>
                    </div>
                    <ScrollToElementBtn
                        elementId="question-answer-display"
                        btnText={qaCountText}
                        className={style.snippetQACount}
                        isDisableScrollText="No Q&A"
                    />
                </div>
                {(allowGuestUserToQuestion || account.isLoggedIn) && (
                    <AccessibleButton
                        tag="button"
                        action={handleOpenPopup}
                        className={style.snippetWriteQALink}
                    >
                        <div className={style.addIcnCntCss}>
                            <img src={AddIcon} alt="Add" />
                        </div>
                        <span>{t('Add Question')}</span>
                    </AccessibleButton>
                )}
            </div>
            {(allowGuestUserToQuestion || account.isLoggedIn) && (
                <Popup isVisible={isPopupVisible} onClose={handleClosePopup} classNameContentCnt={style.desktopViewCnt}>
                    {children}
                    <QuestionForm
                        onClose={handleClosePopup}
                        addQuestion={submitQuestion}
                        questionAddedSuccessfully={!!data}
                        addingQuestion={loading}
                        addingQuestionError={error}
                        isLoggedIn={account.isLoggedIn}
                        email={account.info.email}
                        author={account.info.firstName && `${account.info.firstName} ${account.info.lastName}`}
                    />
                </Popup>
            )}
        </div>
    );
};

const ConnectedQuestionPopup = connectAccount(QuestionPopup);

export { ConnectedQuestionPopup as QuestionPopup };
