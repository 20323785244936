import React, {
    ChangeEvent,
    FocusEvent,
    useState,
    useRef,
    FormEvent,
} from 'react';
import { ApolloError } from '@apollo/client';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import ReCAPTCHA from 'react-google-recaptcha';
import ThankYou from 'ui/component/thankyou/thank-you';
import Input from 'ui/component/input/input';
import Button from 'ui/component/button/button';
import ErrorMessage from 'ui/component/error-message/error-message';
import { IQuestionData } from 'ui/util/type-helper';
import { generateUniqueKey, sanitizeInput, validationHandler } from 'ui/util/validator-helper';
import style from './styles.css';

interface QuestionFormProps {
    onClose: () => void;
    addQuestion:
    (questionData: IQuestionData) =>
    Promise<{ success: boolean; data: {questionId: number|string}|{errors?: ErrorItem[] } | null }>;
    questionAddedSuccessfully: boolean;
    addingQuestion: boolean;
    addingQuestionError: ApolloError | undefined;
    isLoggedIn: boolean;
    email?: string;
    author?: string;
}

interface ErrorItem {
    message: string;
}

export const QuestionForm: React.FC<QuestionFormProps> = ({
    onClose,
    addQuestion,
    questionAddedSuccessfully,
    addingQuestion,
    addingQuestionError,
    isLoggedIn,
    email,
    author,
}) => {
    const [questionData, setQuestionData] = useState({
        question: '',
        email: email ?? '',
        author: author ?? '',
    });

    const [errors, setErrors] = useState({
        question: {
            message: '',
        },
        email: {
            message: '',
        },
        author: {
            message: '',
        },
        submit: {
            message: '',
        },
    });

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const storeConfig = useConfig();
    const reCaptcWebsiteKey = storeConfig.extension_attributes?.cw_enabled_recaptcha_for_question &&
        storeConfig.extension_attributes?.recaptcha_public_key;

    const t = usePhraseTranslater();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const sanitizedValue = sanitizeInput(value);
        setQuestionData(prevData => ({
            ...prevData,
            [name]: sanitizedValue,
        }));
    };

    const setErrorData = (name: string, message: string) => {
        setErrors(prevState => ({
            ...prevState,
            [name]: {
                message,
            },
        }));
    };

    const handleOnBlur = (event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => {
        validationHandler(event, setErrorData);
    };

    const handleSubmit = async (recaptchaToken: string|null) => {
        let error = false;

        Object.keys(questionData).forEach((field) => {
            if (questionData[field as keyof IQuestionData] === '') {
                error = true;
            }
        });

        if (!error) {
            const context = recaptchaToken ? {
                headers: {
                    'X-ReCaptcha': recaptchaToken,
                },
            } : {};
            const questionDataWithRep = { context, ...questionData };
            const response: {
                success: boolean;
                data: {questionId?: number|string; errors?: ErrorItem[] } | null;
            } = await addQuestion(questionDataWithRep);

            if (response.success) {
                setQuestionData({
                    question: '',
                    email: '',
                    author: '',
                });
                onClose();
            } else if (response?.data?.errors && response?.data?.errors.length > 0) {
                setErrorData('submit', response.data.errors[0].message);
            } else {
                setErrorData('submit', t('Internal server error. Please try after sometime.'));
            }
        } else {
            setErrorData('submit', t('Please fill in all fields.'));
        }
    };

    const triggerFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (recaptchaRef.current) {
            recaptchaRef.current.execute();
        } else {
            handleSubmit(null);
        }
    };

    if (questionAddedSuccessfully && !errors && !addingQuestion) {
        return <ThankYou message={t('Thank you for your question!')} />;
    }

    return (
        <form className={style.form} onSubmit={triggerFormSubmit}>
            <Input
                id="question"
                name="question"
                type="textarea"
                label={t('Your Question')}
                placeholder={t('Type your question here...')}
                value={questionData.question}
                onChange={handleInputChange}
                onBlur={handleOnBlur}
                error={errors.question}
                required
            />
            {!isLoggedIn && (
                <>
                    <Input
                        id="email"
                        name="email"
                        type="email"
                        label={t('Your Email')}
                        placeholder={t('Type your email here...')}
                        value={questionData.email}
                        onChange={handleInputChange}
                        onBlur={handleOnBlur}
                        error={errors.email}
                        required
                    />
                    <Input
                        id="author"
                        name="author"
                        type="text"
                        label={t('Enter Your Nickname')}
                        placeholder={t('Type your name here...')}
                        value={questionData.author}
                        onChange={handleInputChange}
                        onBlur={handleOnBlur}
                        error={errors.author}
                        required
                    />
                </>
            )}
            <div>
                {!addingQuestionError && errors.submit.message && (
                    <ErrorMessage
                        message={errors.submit.message}
                    />
                )}
                { addingQuestionError && (
                    <div className={style.gqlErrorCnt}>
                        <ul>
                            {addingQuestionError &&
                                addingQuestionError.graphQLErrors
                                    .map(({ message }, i: number) => (
                                        <li key={generateUniqueKey(i)}>
                                            <ErrorMessage
                                                message={message}
                                            />
                                        </li>
                                    ))}
                        </ul>
                    </div>
                )}
            </div>
            <div className={style.btnCnt}>
                <Button
                    type="submit"
                    disabled={addingQuestion}
                    className={style.submitBtnCnt}
                >
                    { addingQuestion
                        ? <span className={style.submittingBtn}>{t('Submitting')}</span>
                        : <span className={style.submitBtn}>{t('Submit Question')}</span>
                    }
                </Button>
            </div>
            {reCaptcWebsiteKey && (
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={reCaptcWebsiteKey}
                    size="invisible"
                    isolated
                    onChange={(token) => {
                        handleSubmit(token);
                    }}
                />
            )}
        </form>
    );
};
