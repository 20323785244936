import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import ReviewsSnippet from 'ui/component/native-reviews/snippet';
import { WindowSize } from '@silkpwa/module/react-component/window-size';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { Media } from './media';
import { ProductInfo } from './product-info';
import { ProductConfig, IConfiguratorProps } from './product-config';
import { SocialIcons } from './social-icons';
import { MobileGenderCounterpart } from './mobile-gender-counterpart';
import styles from './styles.css';
import { QuestionAnswerForm } from '../product/question-answer/question-answer-form';

export const ProductConfigurator: React.FC<IConfiguratorProps> = ({
    config,
    isQuickView = false,
    popouts,
    handleExit,
}) => {
    const reviewsConfig = useConfig();
    if (reviewsConfig.extension_attributes?.cw_reviews_enabled) {
        return (
            <div className={styles.productView}>
                <div className={styles.mobileProductInfo}>
                    <ProductInfo
                        product={config.simpleProduct}
                        dynamicSku={config.dynamicSku}
                        calculatedPrice={config.calculatedPrice}
                        calculatedOriginalPrice={config.calculatedOriginalPrice}
                    />
                    <WindowSize maxWidth={1040}>
                        <ReviewsSnippet product={config.product} />
                        { reviewsConfig.extension_attributes?.cw_question_answer_enabled && (
                            <QuestionAnswerForm
                                product={config.product}
                                storeId={reviewsConfig.store_config.current_store.id}
                                allowGuestUserToQuestion={reviewsConfig.extension_attributes.cw_allow_guest_to_question}
                            />
                        )}
                    </WindowSize>
                </div>
                <div
                    className={classes(styles.images, {
                        [styles.quickView]: isQuickView,
                    })}
                >
                    <Media
                        product={config.product}
                        images={config.productImages}
                        simpleProduct={config.simpleProduct}
                    />
                    <div className={styles.socialIconsWrap}>
                        <SocialIcons product={config.product} />
                    </div>
                    <MobileGenderCounterpart product={config.simpleProduct} />
                </div>
                <div
                    className={classes(styles.purchaseBox, {
                        [styles.quickView]: isQuickView,
                    })}
                >
                    <div className={styles.desktopProductInfo}>
                        <ProductInfo
                            product={config.simpleProduct}
                            dynamicSku={config.dynamicSku}
                            calculatedPrice={config.calculatedPrice}
                            calculatedOriginalPrice={config.calculatedOriginalPrice}
                            isQuickView={isQuickView}
                        />
                        <WindowSize minWidth={1040}>
                            <ReviewsSnippet product={config.product} />
                            { reviewsConfig.extension_attributes?.cw_question_answer_enabled && (
                                <QuestionAnswerForm
                                    product={config.product}
                                    storeId={reviewsConfig.store_config.current_store.id}
                                    allowGuestUserToQuestion={
                                        reviewsConfig.extension_attributes.cw_allow_guest_to_question
                                    }
                                />
                            )}
                        </WindowSize>
                    </div>
                    <ProductConfig
                        config={config}
                        handleExit={handleExit}
                        isQuickView={isQuickView}
                        popouts={popouts}
                    />
                </div>
                <div className={styles.embeddedEmbConfigWrapper}>
                    <div id="embedded-emb-configurator-content" className={styles.embeddedEmbConfiguratorContent} />
                    <div className={styles.popoutLinksContentWrapper}>
                        <div id="popout-links-content" className={styles.popoutLinksContent} />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className={styles.productView}>
            <div className={styles.mobileProductInfo}>
                <ProductInfo
                    product={config.simpleProduct}
                    dynamicSku={config.dynamicSku}
                    calculatedPrice={config.calculatedPrice}
                    calculatedOriginalPrice={config.calculatedOriginalPrice}
                />
                { reviewsConfig.extension_attributes?.cw_question_answer_enabled && (
                    <WindowSize maxWidth={1040}>
                        <QuestionAnswerForm
                            product={config.product}
                            storeId={reviewsConfig.store_config.current_store.id}
                            allowGuestUserToQuestion={reviewsConfig.extension_attributes.cw_allow_guest_to_question}
                        />
                    </WindowSize>
                )}
            </div>
            <div
                className={classes(styles.images, {
                    [styles.quickView]: isQuickView,
                })}
            >
                <Media
                    product={config.product}
                    images={config.productImages}
                    simpleProduct={config.simpleProduct}
                />
                <div className={styles.socialIconsWrap}>
                    <SocialIcons product={config.product} />
                </div>
                <MobileGenderCounterpart product={config.simpleProduct} />
            </div>
            <div
                className={classes(styles.purchaseBox, {
                    [styles.quickView]: isQuickView,
                })}
            >
                <div className={styles.desktopProductInfo}>
                    <ProductInfo
                        product={config.simpleProduct}
                        dynamicSku={config.dynamicSku}
                        calculatedPrice={config.calculatedPrice}
                        calculatedOriginalPrice={config.calculatedOriginalPrice}
                        isQuickView={isQuickView}
                    />
                    { reviewsConfig.extension_attributes?.cw_question_answer_enabled && (
                        <WindowSize minWidth={1040}>
                            <QuestionAnswerForm
                                product={config.product}
                                storeId={reviewsConfig.store_config.current_store.id}
                                allowGuestUserToQuestion={reviewsConfig.extension_attributes.cw_allow_guest_to_question}
                            />
                        </WindowSize>
                    )}
                </div>
                <ProductConfig
                    config={config}
                    handleExit={handleExit}
                    isQuickView={isQuickView}
                    popouts={popouts}
                />
            </div>
            <div className={styles.embeddedEmbConfigWrapper}>
                <div id="embedded-emb-configurator-content" className={styles.embeddedEmbConfiguratorContent} />
                <div className={styles.popoutLinksContentWrapper}>
                    <div id="popout-links-content" className={styles.popoutLinksContent} />
                </div>
            </div>
        </div>
    );
};
