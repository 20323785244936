import React from 'react';
import { makeVar, useQuery, useReactiveVar } from '@apollo/client';
import axios from 'axios';
import { GET_STORE_CONFIG, IStoreConfig } from 'graphql/config/config';
import { GET_CUSTOMER } from 'graphql/customer';
import { GET_CART } from 'graphql/cart/cart';
import { GET_AVAILABLE_COUNTRIES } from 'graphql/store/country';
import { GET_CUSTOMER_TOKENS, GET_PAYMENT_METHODS } from 'graphql/cart/payment-method';
import { getSessionStorageData } from 'ui/util/session-storage';
import { isSameBillingAndShippingAddress } from 'ui/component/checkout/util/is-same-billing-and-shipping-address';
import { IShippingMethodMethod } from '../../component/checkout/steps/shipping/shipping-method';

// eslint-disable-next-line max-len
import ChefworksGraphQlDataCheckoutSessionEnvelopeInterface = Magento.Definitions.ChefworksGraphQlDataCheckoutSessionEnvelopeInterface;

interface IInitializeCheckoutProps {
    children: React.ReactNode;
}

export interface IAddress {
    firstname: string;
    lastname: string;
    company: string;
    street: string[];
    city: string;
    region: string;
    postcode: string;
    countryCode: string;
    telephone: string;
    saveInAddressBook: boolean;
}

export interface ICustomerAddress {
    id: string;
    firstname: string;
    lastname: string;
    company: string;
    street: string[];
    city: string;
    region: {
        code: string;
        // eslint-disable-next-line camelcase
        region_code: string;
        region: string;
    };
    postcode: string;
    country: {
        code: string;
    };
    // eslint-disable-next-line camelcase
    country_code: string;
    telephone: string;
}

export const emptyAddress: ICustomerAddress = {
    id: '',
    firstname: '',
    lastname: '',
    company: '',
    street: [],
    city: '',
    region: {
        code: '',
        region_code: '',
        region: '',
    },
    postcode: '',
    country: {
        code: '',
    },
    country_code: '',
    telephone: '',
};

interface IToken {
    // eslint-disable-next-line camelcase
    public_hash: string;
    // eslint-disable-next-line camelcase
    payment_method_code: string;
    details: string;
}

interface ITokenDetails {
    type: string;
    maskedCC: string;
    expirationDate: string;
}

export interface IAvailablePaymentMethod {
    code: string;
    title: string;
    details?: ITokenDetails;
}

export interface IAgreedTerm {
    [key: string]: boolean;
}

export interface IAcceptedTerm {
    id: string;
    // eslint-disable-next-line camelcase
    checkbox_text: string;
}

export const CREDIT_KEY = 'creditkey_gateway';
export const REPAY_CC_VAULT = 'repay_cc_vault';
export const PAYPAL_EXPRESS = 'paypal_express';

export const SESSION_KEY = {
    guestPassword: 'guestPassword',
};

export type UntypedMap = {[key: string]: any}; // TODO: anything using this needs proper typing

export interface ICartSummaryExtraData {
    'free_shipping_progress_bar': string;
    checkoutRewardsDiscountLabel: string;
}

export const configVar = makeVar<IStoreConfig | null>(null);
export const cartVar = makeVar<UntypedMap | null>(null); // TODO add type annotation
export const cartIdVar = makeVar(localStorage.getItem('cartId'));
export const cartEmbroideryVar = makeVar(null); // TODO add type annotation
export const cartSummaryItemsVar = makeVar(null); // TODO add type annotation
export const cartSummaryTotalsVar = makeVar(null); // TODO add type annotation
export const cartSummaryExtraDataVar = makeVar<ICartSummaryExtraData | null>(null); // TODO add type annotation
export const customerVar = makeVar(null); // TODO add type annotation
export const defaultBillingAddressVar = makeVar(emptyAddress);
export const defaultShippingAddressVar = makeVar(emptyAddress);
export const isShippingAddressSetVar = makeVar(false);
export const cartShippingAddressVar = makeVar(emptyAddress);
export const cartAvailableShippingMethodsVar = makeVar<IShippingMethodMethod[] | null>(null);
export const cartSelectedShippingMethodVar = makeVar(null);
export const isBillingAddressSetVar = makeVar(false);
export const cartBillingAddressVar = makeVar(emptyAddress);
export const sameAddressVar = makeVar(true);
export const isShippingMethodSetVar = makeVar(false);
export const isPaymentMethodSetVar = makeVar(false);
export const customerEmailVar = makeVar(localStorage.getItem('customerEmail'));
export const customerPasswordVar = makeVar(getSessionStorageData(SESSION_KEY.guestPassword, true) ?? '');
export const isLoggedInVar = makeVar(!!localStorage.getItem('customerToken'));
export const stepsCompletedVar = makeVar({
    customer: false,
    shipping: false,
    billing: false,
    embroidery: false,
    payment: false,
});
export const availableCountriesVar = makeVar([]);
export const paymentMethodsVar = makeVar(null); // TODO add type annotation
export const isGuestVar = makeVar(null);
export const termsAgreedVar = makeVar(getSessionStorageData('termsAgreed') ?? {}); // TODO add type annotation
export const termsForPlaceOrderVar = makeVar(getSessionStorageData('termsForPlaceOrder') ?? []); // TODO add type annotation
export const embroideryAgreedVar = makeVar(getSessionStorageData('embroideryAgreed') === true);
export const shouldTryPlaceOrderVar = makeVar(false);
export const shouldScrollToLoaderVar = makeVar(false);
export const placeOrderErrorVar = makeVar('');
export const placeOrderInfoVar = makeVar('');
export const placeOrderLoadingVar = makeVar(false);
export const orderPlacedVar = makeVar(false);
export const paymentMethodVariablesVar = makeVar<{[key: string]: any}>({});
const InitConfig = () => {
    const { data } = useQuery(GET_STORE_CONFIG);
    configVar(data?.storeConfig);
};

export const InitCustomerSession = async () => {
    try {
        const response = await axios.get<ChefworksGraphQlDataCheckoutSessionEnvelopeInterface>(

            `${process.env.MAGENTO_API}/rest/V1/xmapi/app-customer-checkout-session`,

            { withCredentials: true },

        );
        if (response.data.data.cart_id) {
            localStorage.setItem('cartId', response.data.data.cart_id);

            cartIdVar(response.data.data.cart_id);
        } else {
            window.location.replace('/');
        }

        if (response.data.data.customer_token) {
            localStorage.setItem('customerToken', response.data.data.customer_token);
        } else {
            localStorage.removeItem('customerToken');
        }

        if (response.data.data.customer_email) {
            localStorage.setItem('customerEmail', response.data.data.customer_email);

            customerEmailVar(response.data.data.customer_email);
        } else {
            localStorage.removeItem('customerEmail');

            customerEmailVar('');
        }
    } catch (error) {
        console.error('Error initializing customer session:', error);
    }
};

const GetCustomerAddress = (addresses, ids: number[]) => addresses.filter(address => ids.find(id => id === address.id));
export const InitCustomer = () => {
    const { data } = useQuery(GET_CUSTOMER, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
    });
    customerVar(data?.customer);
    if (data?.customer?.addresses) {
        // TODO : move this logic to shipping step so it will only perform at the shipping step
        const defaultShippingId: number[] = [Number(data?.customer?.default_shipping || 0)];
        const defaultBillingId: number[] = [Number(data?.customer?.default_billing || 0)];
        const defaultIds: number[] = defaultShippingId.concat(defaultBillingId).filter(id => id > 0);
        const defaultCustomerAddress = GetCustomerAddress(data?.customer?.addresses, defaultIds) || [];
        defaultShippingAddressVar(GetCustomerAddress(defaultCustomerAddress, defaultShippingId)[0]);
        defaultBillingAddressVar(GetCustomerAddress(defaultCustomerAddress, defaultBillingId)[0]);
    }
    // clearing stale logged in flag
    isLoggedInVar(!!localStorage.getItem('customerToken'));
    if (isGuestVar() === null) {
        // @ts-ignore
        isGuestVar(!!localStorage.getItem('isExplicitGuest') || !localStorage.getItem('customerToken'));
    }
};
export const GetIsExplicitGuest = () => localStorage.getItem('isExplicitGuest') === 'true'; // Customer has account but chose guest
export const SetIsExplicitGuest = (isGuest: boolean) => {
    localStorage.setItem('isExplicitGuest', isGuest ? 'true' : 'false');
    isGuestVar(isGuest);
};
const InitCountries = () => {
    const { data } = useQuery(GET_AVAILABLE_COUNTRIES);
    availableCountriesVar(data?.countries);
};
const InitCartEmbroidery = () => {
    const cart = useReactiveVar(cartVar);
    cartEmbroideryVar(cart?.items?.reduce((acc, item) => {
        if (item?.embroidery_item) {
            const embroideryItemId = item.embroidery_item.toString();
            const parentIndex = acc.findIndex(parentItem => parentItem.id === embroideryItemId);
            if (parentIndex !== -1) {
                acc[parentIndex].embroideryOptions.push(item);
            } else {
                const parentItem = cart.items.find(cartItem => cartItem.id === embroideryItemId);
                // Prevent from adding EMB skus to itself as embroideryOptions
                if (parentItem && embroideryItemId !== item.id) {
                    acc.push({
                        ...parentItem,
                        embroideryOptions: [item],
                    });
                }
            }
        }
        return acc;
    }, []));
};
/**
 * This function is used in checkout cart summary section. DO NOT GROW THIS FUNCTION.
 * TODO: we need to add typing for cartSummaryItemsVar
 */
const InitCartSummaryItems = () => {
    const cart = useReactiveVar(cartVar);
    // TODO : remove hardcoded non editable sku checking
    const isUserCanEdit = (sku: string) => sku !== 'DIGITIZE0000';
    cartSummaryItemsVar(cart?.items?.reduce((acc, item) => {
        const userCanEdit = isUserCanEdit(item.product.sku);
        if (item?.embroidery_item) {
            const embroideryItemId = item.embroidery_item.toString();
            const parentIndex = acc.findIndex(parentItem => parentItem.id === embroideryItemId);
            if (parentIndex !== -1) {
                if (!acc[parentIndex].embroideryOptions) {
                    acc[parentIndex].embroideryOptions = [];
                }
                acc[parentIndex].embroideryOptions.push({
                    ...item,
                    userCanEdit,
                });
            } else {
                const parentItem = cart.items.find(cartItem => cartItem.id === embroideryItemId);
                // Prevent from adding EMB skus to itself as embroideryOptions
                if (parentItem && embroideryItemId !== item.id) {
                    acc.push({
                        ...parentItem,
                        userCanEdit,
                        embroideryOptions: [item],
                    });
                }
            }
        } else {
            acc.push({
                ...item,
                userCanEdit,
            });
        }
        return acc;
    }, []));
};

const InitCartSummaryTotals = () => {
    const cart = useReactiveVar(cartVar);
    // @ts-ignore
    cartSummaryTotalsVar({
        prices: cart?.prices || {},
        applied_reward_points: cart?.applied_reward_points || {},
        applied_store_credit: cart?.applied_store_credit || {},
        shipping_addresses: cart?.shipping_addresses || [],
        applied_gift_cards: cart?.applied_gift_cards || [],
    });
};

const InitCartSummaryExtraData = () => {
    const config = useReactiveVar(configVar);
    const cart = useReactiveVar(cartVar);
    // @ts-ignore
    cartSummaryExtraDataVar({
        free_shipping_progress_bar: cart?.free_shipping_progress_bar || '',
        checkoutRewardsDiscountLabel: config?.loyalty_config?.checkoutRewardsDiscountLabel || 'Rewards Discount',
    });
};

const getCombinedPaymentMethods = (
    methods: IAvailablePaymentMethod[],
    tokens?: IToken[],
): IAvailablePaymentMethod[] | undefined => {
    const repayCCVault: IAvailablePaymentMethod | undefined = methods.find(
        (method: IAvailablePaymentMethod) => method.code === REPAY_CC_VAULT,
    );
    const hasVault = !!repayCCVault;
    if (!hasVault) {
        return methods;
    }

    const methodsWithoutVault: IAvailablePaymentMethod[] = methods.filter(
        (method: IAvailablePaymentMethod) => method.code !== REPAY_CC_VAULT,
    );
    if (!tokens || !tokens.length) {
        return methodsWithoutVault;
    }

    const tokenMethods: IAvailablePaymentMethod[] = [];
    tokens.forEach((token: IToken): void => {
        try {
            const details: ITokenDetails = JSON.parse(token.details) ?? {
                type: '',
                maskedCC: '',
                expirationDate: '',
            };
            const code = `${REPAY_CC_VAULT}_${token.public_hash}`;

            tokenMethods.push({
                title: 'STORED CARD',
                code,
                details,
            });
        } catch (error) {
            // skip adding payment token on an error
        }
    });

    return [
        ...tokenMethods,
        ...methodsWithoutVault,
    ];
};

/**
 * Init all cart summary var
 * @constructor
 */
const InitCartSummary = () => {
    InitCartSummaryItems();
    InitCartSummaryTotals();
    InitCartSummaryExtraData();
};

const InitPaymentMethods = () => {
    const cartId = useReactiveVar(cartIdVar);
    const { data } = useQuery(GET_PAYMENT_METHODS, { variables: { cartId } });
    const paymentMethods = data?.cart?.available_payment_methods as IAvailablePaymentMethod[];
    const paymentTokensData = useQuery(GET_CUSTOMER_TOKENS);
    const paymentTokens = paymentTokensData?.data?.customerPaymentTokens?.items as IToken[];
    const combinedPaymentMethods = paymentMethods ? getCombinedPaymentMethods(
        paymentMethods,
        paymentTokens,
    ) : paymentMethods;
    // @ts-ignore
    paymentMethodsVar(combinedPaymentMethods);
};

export const getInitialAddressState = (customerAddress: ICustomerAddress | null): IAddress => ({
    firstname: customerAddress ? customerAddress?.firstname : '',
    lastname: customerAddress ? customerAddress?.lastname : '',
    company: customerAddress ? customerAddress?.company : '',
    street: customerAddress ? customerAddress?.street : [],
    city: customerAddress ? customerAddress?.city : '',
    region: customerAddress ? customerAddress?.region?.code ?? customerAddress?.region?.region_code : '',
    postcode: customerAddress ? customerAddress?.postcode : '',
    countryCode: customerAddress ? customerAddress?.country?.code ?? customerAddress?.country_code : '',
    telephone: customerAddress ? customerAddress?.telephone : '',
    saveInAddressBook: true,
});

/**
 * Avalara Address Validation
 */
export const showVerifyAddressVar = makeVar(false);
export const validatedAddressVar = makeVar(getInitialAddressState(emptyAddress));
export const originalAddressVar = makeVar(getInitialAddressState(emptyAddress));
export const shouldValidateAddressVar = makeVar(false);

const InitCart = async () => {
    const cartId = useReactiveVar(cartIdVar);
    const cart = useReactiveVar(cartVar);
    const { data } = await useQuery(GET_CART, { variables: { cartId } });
    const shippingAddress = cart?.shipping_addresses[0];
    if (shippingAddress) {
        cartShippingAddressVar(shippingAddress);
        isShippingAddressSetVar(true);
        cartAvailableShippingMethodsVar(shippingAddress?.available_shipping_methods);
        cartSelectedShippingMethodVar(shippingAddress?.selected_shipping_method?.method_code ?? null);
        // Fix for completed state on page reload as per CWM2-8275 TODO refactor this
        if (cartSelectedShippingMethodVar() && localStorage.getItem('shippingStepSaved') === '1') {
            stepsCompletedVar({ ...stepsCompletedVar(), shipping: true });
        }
    }
    const billingAddress = cart?.billing_address;
    if (billingAddress) {
        cartBillingAddressVar(billingAddress);
        isBillingAddressSetVar(true);
        /**
         * Cart object does not tell us if the customer selected the same address, so we have to check
         */
        sameAddressVar(!!cart?.is_virtual && isSameBillingAndShippingAddress(billingAddress, shippingAddress));
        // Fix for completed state on page reload as per CWM2-8275 TODO refactor this
        stepsCompletedVar({ ...stepsCompletedVar(), billing: true });
    }
    isShippingMethodSetVar(cart?.shipping_addresses[0]?.selected_shipping_method !== null);
    isPaymentMethodSetVar(cart?.selected_payment_method.code !== '');
    if (!cart && data?.cart) {
        // This should prevent from cart object to be updated all the time
        cartVar(data?.cart);
    }
};

/**
 * Init step completion status
 * @constructor
 */
const InitStepsCompleted = () => {
    /**
     * Customer
     */
    const loggedIn = isLoggedInVar();
    const email = customerEmailVar();
    const isGuest = isGuestVar();
    const customer = customerVar();
    const cart = cartVar();
    const isCustomerStepComplete = Boolean((loggedIn && customer) || (!!email && isGuest));
    /**
     * Shipping
     */
    const cartShippingAddress = cartShippingAddressVar();
    const cartShippingMethod = cartSelectedShippingMethodVar();
    const isShippingStepComplete = Boolean(
        (!!cartShippingAddress &&
            !!cartShippingMethod &&
            localStorage.getItem('shippingStepSaved') === '1') ||
        (cart?.is_virtual),
    );
    /**
     * Billing
     */
    const isBillingStepComplete = isBillingAddressSetVar();
    /**
     * Embroidery
     */
    const embroideryItems = cartEmbroideryVar();
    const isEmbroideryStepComplete =
        !((embroideryItems !== undefined && embroideryItems.length > 0) && !embroideryAgreedVar());
    /**
     * Payment
     */
    const isPaymentStepComplete = false; // Currently this step is never completed until order is placed
    /**
     * Set step completion status
     */
    stepsCompletedVar({
        customer: isCustomerStepComplete,
        shipping: isShippingStepComplete,
        billing: isBillingStepComplete,
        embroidery: isEmbroideryStepComplete,
        payment: isPaymentStepComplete,
    });
};

export const InitializeCheckout = ({ children }: IInitializeCheckoutProps) => {
    InitConfig();
    InitCart();
    InitCustomer();
    InitCountries();
    InitPaymentMethods();
    InitCartEmbroidery();
    InitCartSummary();
    InitStepsCompleted();
    return <>{children}</>;
};

export const resetCustomer = () => {
    localStorage.removeItem('customerToken');
    localStorage.removeItem('customerEmail');
    localStorage.removeItem('isExplicitGuest');
    customerEmailVar(null);
    isLoggedInVar(false);
};
